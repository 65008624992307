.orthodonticAnomalies {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .orthodonticAnomalies-container {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 720px;
    .orthodonticAnomalies-container-wrapper {
      display: flex;
      position: relative;
      width: 100%;
      max-width: inherit;
      overflow-x: hidden;
      .orthodonticAnomalies-container-wrapper-slide {
        display: flex;
        transform: translateX(0%);
        transition: transform 0.3s;
        .orthodonticAnomalies-container-wrapper-slide-part {
          flex-basis: 100%;
          display: grid;
          grid-template: minmax(auto, 260px) / 210px 210px 210px;
          grid-gap: 30px;
          padding: 5px 15px;
          @media screen and (max-width: 1024px) {
            grid-template: minmax(auto, 260px) / 210px 210px;
          }
          //@media screen and (max-width: 900px) {
          //  grid-template: auto / 230px 230px;
          //}
        }
      }
    }
    .orthodonticAnomalies-container-slide-controls {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: 12px 24px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(22, 22, 22, 0.1);
      background: rgba(255, 255, 255, 0.3);
      transition: box-shadow 0.3s;
      cursor: pointer;
      &.back {
        left: -80px;
      }
      &.forward {
        right: -80px;
      }
      &:hover {
        box-shadow: 0 0 10px rgba(22, 22, 22, 0.3);
        transition: box-shadow 0.6s;
      }
      &:active {
        box-shadow: inset 0 0 3px rgba(22, 22, 22, 0.3);
      }
    }
  }
}
