.mediaLibrary {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--main-background) no-repeat center top/cover;
  z-index: 99;
  .mediaLibrary-content {
    display: flex;
    position: relative;
    width: 100%;
    height: calc(100vh - 140px);
    .mediaLibrary-view {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      //max-height: calc(100vh - 100px);
      .mainCategoriesList {
        display: flex;
        flex-wrap: wrap;
        max-width: 1366px;
        margin: 0 auto;
        padding: 50px 90px;
        .mainCategoriesList-item {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          padding: 10px 10px;
          margin: 0 10px 10px;
          border: 0 solid transparent;
          border-radius: 14px;
          width: 100%;
          max-width: calc(50% - 20px);
          cursor: pointer;
          background: linear-gradient(to right, var(--primary-blue), var(--primary-red));
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
          z-index: 1;
          &:before {
            content: "";
            position: absolute;
            top: 4px;
            left: 4px;
            right: 4px;
            bottom: 4px;
            border-radius: 10px;
            background: var(--main-background-color);
            z-index: -1;
          }
          .mainCategoriesList-item-preview {
            display: block;
            width: 100%;
            max-width: 150px;
            height: auto;
            object-fit: cover;
          }
          .mainCategoriesList-item-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            margin-left: 10px;
            .mainCategoriesList-item-info-title {
              text-align: left;
              font-weight: bold;
              font-size: 18px;
              margin-bottom: 5px;
              color: var(--dark-text);
            }
            .mainCategoriesList-item-info-description {
              font-size: 14px;
              text-align: left;
              color: var(--dark-text);
            }
          }
        }
      }
      .subCategoryLayout {
        display: flex;
        justify-content: center;
        padding-left: 90px;
        padding-bottom: 10px;
        height: 100%;
        .subCategoryListContainer {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 370px;
          .subCategoryList-title {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 5px;
          }
          .subCategoryList {
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            height: 100%;
            .subCategoryList-item {
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              padding: 12px;
              margin: 5px;
              border: 0 solid transparent;
              border-radius: 14px;
              background: transparent;
              z-index: 1;
              cursor: pointer;
              box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
              &.active {
                background: linear-gradient(to right, var(--primary-blue), var(--primary-red));
              }
              &:before {
                content: "";
                position: absolute;
                top: 4px;
                left: 4px;
                right: 4px;
                bottom: 4px;
                border-radius: 10px;
                background: var(--main-background-color);
                z-index: -1;
              }

              .subCategoryList-item-preview {
                display: block;
                width: 100%;
                max-width: 130px;
                height: auto;
                object-fit: cover;
              }
              .subCategoryList-item-title {
                margin-left: 5px;
                font-size: 14px;
                font-weight: bold;
                width: 100%;
                color: var(--dark-text);
              }
            }
          }
        }
        .subCategoryContent {
          width: 100%;
          //padding: 0 10px;
          height: 100%;
          .subCategoryContent-search {
            //display: flex;
            //margin-left: 10px;
            //margin-bottom: 10px;
            position: relative;
            width: 100%;
            //max-width: 500px;
            //border: 2px solid var(--primary-blue);
            //border-radius: 28px;
            //background: rgb(255, 255, 255);
            //background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(204, 204, 204, 0.7) 100%);
            //.searchIcon {
            //  display: flex;
            //  align-items: center;
            //  justify-content: center;
            //  padding: 5px 0 5px 10px;
            //  color: var(--primary-blue-text);
            //}
            //input {
            //  outline-color: transparent;
            //  outline-width: 0;
            //  color: var(--primary-blue-text);
            //  border: 0 solid transparent;
            //  //border: 2px solid var(--primary-blue);
            //  //border-radius: 28px;
            //  padding: 10px 15px;
            //  width: 100%;
            //  background: transparent;
            //  //background: rgb(255, 255, 255);
            //  //background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(204, 204, 204, 0.7) 100%);
            //}
            //.clearBtn {
            //  display: flex;
            //  align-items: center;
            //  justify-content: center;
            //  border: 0 solid transparent;
            //  background: transparent;
            //  padding: 5px 10px;
            //  cursor: pointer;
            //  &:hover {
            //    color: var(--primary-blue-text);
            //  }
            //}
          }
          .subCategoryContent-parts {
            display: flex;
            height: 100%;
            .subCategoryContent-part {
              display: flex;
              flex-direction: column;
              width: calc(100% / 3);
              padding: 0 10px;
              height: 100%;
              //.subCategoryContent-part-title {
              //  font-weight: bold;
              //  border: 0 solid transparent;
              //  border-radius: 28px;
              //  padding: 10px 15px;
              //  margin-bottom: 10px;
              //  background: rgb(255, 255, 255);
              //  background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(204, 204, 204, 0.7) 100%);
              //  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
              //}
              .subCategoryContent-part-list {
                display: flex;
                flex-direction: column;
                padding-bottom: 10px;
                height: calc(100% - 20px);
                overflow-y: scroll;
                .subCategoryContent-part-list-item {
                  display: block;
                  position: relative;
                  cursor: pointer;
                  margin-bottom: 20px;
                  &.videoItem:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: url("../assets/images/video_play_icon.png") no-repeat center center;
                  }
                  .image {
                    display: block;
                    width: 100%;
                    height: auto;
                  }
                  .title {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    padding: 5px 5px;
                    background: rgba(0, 0, 0, 0.6);
                    text-align: center;
                    font-size: 0.9em;
                    color: #fff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
