.adentyFullTypeImplants {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 5px 5px 90px 90px;
  .adentyFullTypeImplants-types {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 240px;
    margin-right: 20px;
    p {
      font-size: 1.1em;
      font-weight: bold;
      text-align: center;
      margin-bottom: 10px;
    }
  }
  .adentyFullTypeImplants-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
