.genderSelect {
  background: var(--main-background) no-repeat center center/cover;
  height: 100%;
  font-family: "Roboto", sans-serif;

  .genderSelect-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 170px);
    .genderSelect-list {
      display: grid;
      grid-template: auto / 300px 300px;
      grid-gap: 30px;
      @media screen and (max-width: 1024px) {
        grid-template: auto / 280px 280px;
      }
      @media screen and (max-width: 900px) {
        grid-template: auto / 230px 230px;
      }
    }
  }
}
