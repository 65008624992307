.autoTreatment {
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;
  padding: 5px 5px 90px 90px;
  //.switchSceneToggle {
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  right: 0;
  //  text-align: center;
  //  z-index: 9;
  //  @media screen and (max-width: 500px) {
  //    bottom: 83px;
  //    left: 10px;
  //    right: 10px;
  //  }
  //  .switchSceneToggle-title {
  //    font-size: 18px;
  //    font-weight: bold;
  //    margin-bottom: 15px;
  //  }
  //  .switchSceneToggle-container {
  //    background: rgba(255, 255, 255, 0.65);
  //  }
  //}
  .autoTreatment-3dContainer {
    width: 100%;
    height: 100%;
  }
}
