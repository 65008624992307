.optionSelect {
  display: flex;
  position: absolute;
  width: 470px;
  flex-wrap: wrap;
  .optionSelect-item {
    display: flex;
    align-items: center;
    width: 50%;
    background-color: var(--main-background-color);
    min-height: 40px;
    padding: 3px 10px 3px 3px;
    border: 1px solid #dddddd;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      border-color: #333;
    }
    img {
      width: 40px;
      height: 35px;
      margin-right: 3px;
    }
  }
}
