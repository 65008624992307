$wrapper: 1100px;
$spacing: 8px;

.mainPage {
  display: block;
  width: 100%;
  min-height: 100vh;
  background-image: url("./assets/bg.png");
  background-size: cover;
  background-position: top center;
  .mainPage-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: $wrapper;
    margin: 0 auto;
    padding: $spacing * 2 $spacing * 2;
  }
  .mainPage-section1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: $wrapper;
    margin: 0 auto;
    padding: $spacing $spacing;
    .mainPage-section1-title {
      font-family: "Syncopate", sans-serif;
      font-size: 48px;
      text-align: center;
      color: #fff;
      margin-top: $spacing * 6;
      margin-bottom: $spacing * 6;
    }
    .mainPage-section1-logo {
      display: block;
      width: 100%;
      max-width: 210px;
      height: auto;
    }
    .mainPage-section1-subTitle {
      font-family: "Syncopate", sans-serif;
      font-size: 36px;
      text-align: center;
      color: #fff;
      margin-top: $spacing * 6;
      margin-bottom: $spacing * 4;
      text-transform: uppercase;
    }
  }
}
