.formula3dPreview {
  position: relative;
  //max-height: 380px;
  width: 100%;
  height: 100%;
  //padding: 10px 10px 0;
  //margin-bottom: 15px;
  &.fullScreen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    background: var(--fullscreen-background);
    z-index: 999999;
  }

  .formula3dPreview-sectionSelectRestoreBtn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 400px;
    z-index: 99;
  }

  .formula3dPreview-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 185px;
    text-align: center;
    z-index: 9;
    .controlLabel {
      padding: 0 20px;
      //font-weight: bold;
    }
  }

  .formula3dPreview-whatsNext {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    z-index: 0;
  }
  .formula3dPreview-view {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .fullScreenBtn {
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 0;
    max-width: 226px;
    width: 100%;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    .fullScreenBtn-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      font-size: 18px;
      margin-right: 15px;
      border: 0 solid transparent;
      border-radius: 15px;
      background: linear-gradient(
        to right,
        rgba(30, 136, 229, 1) 0%,
        rgba(16, 83, 141, 1) 50%,
        rgba(30, 136, 229, 1) 100%
      );
      color: #fff;
    }
  }
  &.fullScreen {
    .fullScreenBtn {
      right: 15px;
      max-width: none;
      width: auto;
    }
  }
  //.formulaContainer-controls-btn {
  //  display: none;
  //}

  //#formulaContainer {
  //  display: block;
  //  position: relative;
  //  width: 100%;
  //  height: 100%;
  //  max-height: 380px;
  //}

  //&.fullScreen {
  //  width: 100%;
  //  max-width: 100%;
  //  max-height: 100%;
  //  padding: 0;
  //  margin: 0;
  //  #formulaContainer {
  //    width: 100%;
  //    height: 100%;
  //    max-height: 100%;
  //  }
  //}
}

//@media screen and (max-width: 768px) {
//  #formula3dPreview {
//    .formulaContainer-controls-btn {
//      display: none;
//    }
//    &.fullScreen {
//      .formulaContainer-controls-btn {
//        position: absolute;
//        top: 50px;
//        right: 16px;
//        font-size: 1.5rem;
//        z-index: 9;
//        width: 35px;
//        height: 35px;
//        border-radius: 50%;
//        cursor: pointer;
//        display: flex;
//        justify-content: center;
//        align-items: center;
//        i {
//          font-size: 25px;
//          color: #293f88;
//        }
//      }
//      #formulaContainer-controls {
//        transition: 0.3s;
//        top: 100px;
//        transform: scale(0);
//        transform-origin: top;
//        &.opened {
//          background: rgba(255, 255, 255, 0.8);
//          border-radius: 10px;
//          transform: scale(1);
//          padding: 5px 0 5px 10px;
//        }
//      }
//    }
//  }
//}
