.finishStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0 0 0 90px;
  .autoTreatment-switchToggle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 9;
    @media screen and (max-width: 500px) {
      bottom: 83px;
      left: 10px;
      right: 10px;
    }
    .autoTreatment-switchToggle-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;
    }
  }
  .finishStep-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    //max-width: 820px;
    height: 100%;
  }
  .saveFormAction {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 20px;
    .selectBottomBarInfoBtn {
      margin-top: 10px;
      max-width: 365px;
    }
    //.saveFormAction-btn {
    //  display: block;
    //  position: relative;
    //  padding: 10px 18px;
    //  color: #fff;
    //  border: 2px solid #fff;
    //  border-radius: 24px;
    //  font-size: 18px;
    //  font-weight: bold;
    //  min-width: 230px;
    //  box-shadow: 0 0 3px rgba(0, 0, 0, 0.6);
    //  background: linear-gradient(
    //    to right,
    //    rgba(30, 136, 229, 1) 0%,
    //    rgba(16, 83, 141, 1) 50%,
    //    rgba(30, 136, 229, 1) 100%
    //  );
    //}
    .alternateAdentySolution {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-top: 10px;

      .alternateAdentySolution-icon {
        display: block;
        width: 50px;
        height: 50px;
        object-fit: contain;
        margin-right: 10px;
        margin-left: 50px;
      }
      .alternateAdentySolution-text {
        display: block;
        margin-right: 10px;
        max-width: 700px;
        background: var(--transparent-banner);
        padding: 10px 10px;
        text-align: left;
        border: 0 solid transparent;
        border-radius: 8px;
        font-family: "Roboto Condensed";
        font-weight: bold;
        margin-left: 10px;
        min-height: 60px;
        font-size: 16px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
      }
      //.alternateAdentySolution-btn {
      //  display: block;
      //  position: relative;
      //  padding: 8px 18px;
      //  color: #fff;
      //  border: 2px solid #fff;
      //  border-radius: 24px;
      //  font-size: 16px;
      //  font-weight: bold;
      //  box-shadow: 0 0 3px rgba(0, 0, 0, 0.6);
      //  background: linear-gradient(
      //    to right,
      //    rgba(30, 136, 229, 1) 0%,
      //    rgba(16, 83, 141, 1) 50%,
      //    rgba(30, 136, 229, 1) 100%
      //  );
      //}
    }
  }
}
