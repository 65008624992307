.bottomBarInfo {
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 0;
  right: 0;
  bottom: -100%;
  background: var(--main-background-color);
  max-height: 500px;
  height: 100%;
  width: 100%;
  box-shadow: -7px 0px 7px rgba(0, 0, 0, 0.3);
  transition: bottom 0.3s ease-in;
  z-index: 99;
  .bottomBarInfo-backdrop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &.open {
    bottom: 0;
    .bottomBarInfo-backdrop {
      display: block;
    }
  }
  .bottomBarInfo-contentView {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1 1;
    height: 100%;
    padding: 20px 20px;
    .contentContainer {
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      @media screen and (max-width: 980px) {
        flex-direction: column;
      }

      .contentMedia {
        position: relative;
        max-width: 570px;
        width: 100%;
        .contentMedia-title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .contentMedia-wrapper {
          //position: relative;
          //padding-bottom: 56.25%; /* 16:9 */
          //height: 0;
          margin-bottom: 10px;
        }
      }

      .contentText {
        width: 100%;
        padding: 0 20px;
        @media screen and (max-width: 980px) {
          padding: 20px 0;
        }
        .contentText-title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .contentText-wrapper {
          line-height: 1.2;
          //text-align: justify;
          &.single {
            margin-right: 50px;
          }
          strong,
          b {
            font-weight: bold;
          }
        }
      }
    }

    .actionBtnsRow {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
  .bottomBarInfo-contentListWrapper {
    flex: 1 1;
    max-width: 350px;
    background-color: var(--bar-info-content-list-background);
    padding: 10px 10px 0 10px;
    height: 100%;
    position: relative;
    z-index: 2;
    .contentMedia-title {
      display: block;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      margin-left: 50px;
      margin-bottom: 5px;
    }
    .contentMedia-switchers {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      position: absolute;
      left: 0;
      top: 0;
      padding: 10px;
      text-align: center;
      background: var(--bar-info-content-list-background);
      z-index: 4;

      label {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        width: 100%;
        max-width: 40px;
        font-size: 14px;
        margin-bottom: 10px;
        cursor: pointer;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 40px;
          border: 2px solid transparent;
          border-radius: 50%;
          color: #fff;
          //background: #aaa;
          font-size: 15px;
          cursor: pointer;
          img {
            display: block;
            width: 100%;
            height: 100%;
            //object-fit: cover;
          }
          &:last-child {
            margin-bottom: 0;
          }
          &:hover {
            background: #ddd;
          }
          &.active {
            border-color: var(--primary-blue);
            background: #fff;
            color: var(--primary-blue-text);
          }
        }
      }
    }
    .contentMedia-contentList {
      overflow-y: scroll;
      flex: 1 1;
      height: 100%;
      margin-left: 50px;
      .contentMedia-contentList-item {
        display: block;
        position: relative;
        cursor: pointer;
        margin-bottom: 10px;
        &.videoItem:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: url("../../assets/images/video_play_icon.png") no-repeat
            center center;
        }
        .image {
          display: block;
          width: 100%;
          height: auto;
        }
        .video {
          position: relative;
          padding-bottom: 56.25%; /* 16:9 */
          height: 0;
          video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        .title {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          padding: 5px 5px;
          background: rgba(0, 0, 0, 0.6);
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

//.videoWrapper {

//}
