.prosthesisSelect {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 90px 0;
  .prosthesisSelect-list {
    //display: flex;
    //align-items: flex-start;
    //justify-content: center;
    //flex-wrap: wrap;
    //max-width: 1000px;
    display: grid;
    grid-template-columns: repeat(3, minmax(180px, 300px));
    grid-template-rows: repeat(1, minmax(100px, auto));
    grid-gap: 30px;
    max-height: calc(100vh - 275px);
  }
}
