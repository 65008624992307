.viewerVR {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  .videoFullScreen-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
  }
  .viewerVR-top {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
  }
  .viewerVR-container {
    position: relative;
    //height: 100%;

    .viewerVR-container-img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .viewerVR-container-helper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      bottom: 40px;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 3;
      text-align: center;
      img {
        display: block;
        width: 100%;
        max-width: 500px;
        height: auto;
      }
      span {
        display: block;
        background: rgba(255, 255, 255, 0.68);
        margin-top: 10px;
        padding: 10px 15px;
        border: 0 solid transparent;
        border-radius: 16px;
        text-transform: uppercase;
        text-align: center;
        font-size: 20px;
        color: #333;
      }
    }
  }
  .viewerVR-close {
    position: absolute;
    top: 5px;
    right: 5px;
    background: #fff;
    color: #333;
    z-index: 9;
    &:hover {
      color: #fff;
    }
  }
}
