.screenSaver {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-image: url("./images/bg.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #333;
  color: #fff;
  // Change controls position
  .formula3dPreview {
    .fullScreenBtn {
      display: none;
    }
    .formula3dPreview-controls {
      top: 200px;
      .formula3dPreview-controls-popUpInfo {
        color: var(--dark-text);
      }
    }
  }

  .screenSaver-leftBanner {
    position: absolute;
    top: 170px;
    left: 5px;
    bottom: 170px;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .screenSaver-runButton {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 18px;
    //width: 100%;
    //max-width: 340px;
    border: 0 solid transparent;
    border-radius: 36px;
    background: linear-gradient(var(--primary-red), var(--primary-blue));
    color: #fff;
    font-size: 22px;
    cursor: pointer;
    z-index: 9;
    &:before {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      background: #000;
      border: 0 solid transparent;
      border-radius: 36px;
      z-index: -1;
    }
  }
  .screenSaver-types {
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    z-index: 9;
    .screenSaver-types-list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 auto;
      .screenSaver-types-list-item {
        margin: 10px 5px;
        max-width: 120px;
        width: 100%;
        cursor: pointer;
        .screenSaver-types-list-item-preview {
          display: block;
          width: 100%;
          height: auto;
          border: 4px solid rgba(255, 255, 255, 0.5);
          border-radius: 18px;
        }
        &.active {
          .screenSaver-types-list-item-preview {
            border-color: #4da9ff;
          }
        }
        .screenSaver-types-list-item-title {
          display: block;
          width: 100%;
          margin-top: 10px;
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }
  .screenSaver-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
