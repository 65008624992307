.treatmentView {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  padding: 5px 5px 90px 90px;
  .treatmentView-nextBtn {
    position: absolute;
    bottom: 90px;
    left: 50%;
    max-width: 350px;
    transform: translateX(-50%);
    z-index: 9;
  }
}
