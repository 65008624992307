.stepControls {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  width: 100%;
  padding: 20px 20px;
  &.extraPadding {
    padding-bottom: 60px;
  }

  .stepControls-btn {
    align-self: start;
    &.back {
      justify-self: start;
    }
    &.next {
      justify-self: end;
    }
  }

  .stepControls-info {
    display: flex;
    align-items: center;
    position: relative;
    //min-width: 600px;
    max-width: 660px;
    width: 100%;
    justify-self: center;
    .stepControls-info-logo {
      display: block;
      width: 60px;
      height: 60px;
      margin-right: 10px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: scale-down;
      }
    }
    .stepControls-info-title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1 1;
      position: relative;
      background: linear-gradient(to right, var(--primary-red), var(--primary-blue));
      border: 0 solid transparent;
      min-height: 60px;
      border-radius: 50px;
      padding: 10px 20px;
      color: var(--primary-blue-text);
      font-size: 19px;
      font-weight: bold;
      text-align: center;
      z-index: 1;
      &:before {
        content: "";
        position: absolute;
        top: 4px;
        left: 8px;
        right: 8px;
        bottom: 4px;
        background: var(--main-background-color);
        border: inherit;
        border-radius: inherit;
        z-index: -1;
      }
    }
    .stepControls-info-subTitle {
      position: absolute;
      top: calc(100% + 10px);
      left: 0;
      right: 0;
      font-size: 15px;
      color: var(--dark-text);

      display: flex;
      align-items: center;
      background: var(--transparent-banner);
      padding: 8px 10px;
      text-align: justify;
      border: 0 solid transparent;
      border-radius: 8px;
      font-family: "Roboto Condensed";
      font-weight: bold;
      //margin-left: 50px;
      //margin-right: 60px;
      //min-height: 60px;

      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
      .subTitleIcon {
        display: block;
        width: 30px;
        height: 30px;
        object-fit: contain;
        margin-right: 10px;
      }
      .subTitleText {
        width: 100%;
        b {
          font-weight: bold;
        }
        .controls-heading {
          text-align: center;
          font-size: 18px;
        }
      }
    }
  }
}
