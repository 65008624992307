.orthodonticSelect {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .orthodonticSelect-list {
    //display: flex;
    //align-items: center;
    //justify-content: center;
    //flex-wrap: wrap;
    //max-width: 900px;
    display: grid;
    grid-template: auto / 300px 300px;
    grid-gap: 30px;
    @media screen and (max-width: 1024px) {
      grid-template: auto / 250px 250px;
    }
    @media screen and (max-width: 900px) {
      grid-template: auto / 230px 230px;
    }
  }
}
