.stepLayout-footer {
  display: flex;
  align-items: center;
  position: fixed;
  left: 50%;
  bottom: 20px;
  max-width: 900px;
  width: 100%;
  transform: translateX(-50%);
  .stepLayout-footer-icon {
    display: block;
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-right: 10px;
  }
  .stepLayout-footer-text {
    display: block;
    width: 100%;
    background: var(--transparent-banner);
    padding: 10px 120px 10px 10px;
    text-align: justify;
    border: 0 solid transparent;
    border-radius: 8px;
    font-family: "Roboto Condensed";
    margin-left: 20px;
    margin-right: 60px;
    min-height: 60px;
    font-size: 15px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);

    b {
      font-weight: bold;
    }
  }
}
