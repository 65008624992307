@font-face {
  font-family: "Roboto";
  src: local("Roboto Thin"), local("Roboto-Thin"), url("./Robotothin.woff2") format("woff2"),
    url("./Robotothin.woff") format("woff"), url("./Robotothin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto Thin Italic"), local("Roboto-ThinItalic"), url("./Robotothinitalic.woff2") format("woff2"),
    url("./Robotothinitalic.woff") format("woff"), url("./Robotothinitalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto Light"), local("Roboto-Light"), url("./Robotolight.woff2") format("woff2"),
    url("./Robotolight.woff") format("woff"), url("./Robotolight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url("./Robotolightitalic.woff2") format("woff2"),
    url("./Robotolightitalic.woff") format("woff"), url("./Robotolightitalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), local("Roboto-Regular"), url("./Roboto.woff2") format("woff2"),
    url("./Roboto.woff") format("woff"), url("./Roboto.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto Italic"), local("Roboto-Italic"), url("./Robotoitalic.woff2") format("woff2"),
    url("./Robotoitalic.woff") format("woff"), url("./Robotoitalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto Medium"), local("Roboto-Medium"), url("./Robotomedium.woff2") format("woff2"),
    url("./Robotomedium.woff") format("woff"), url("./Robotomedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"), url("./Robotomediumitalic.woff2") format("woff2"),
    url("./Robotomediumitalic.woff") format("woff"), url("./Robotomediumitalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto Bold"), local("Roboto-Bold"), url("./Robotobold.woff2") format("woff2"),
    url("./Robotobold.woff") format("woff"), url("./Robotobold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url("./Robotobolditalic.woff2") format("woff2"),
    url("./Robotobolditalic.woff") format("woff"), url("./Robotobolditalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto Black"), local("Roboto-Black"), url("./Robotoblack.woff2") format("woff2"),
    url("./Robotoblack.woff") format("woff"), url("./Robotoblack.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto Black Italic"), local("Roboto-BlackItalic"), url("./Robotoblackitalic.woff2") format("woff2"),
    url("./Robotoblackitalic.woff") format("woff"), url("./Robotoblackitalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
