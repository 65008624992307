.formulaTotalInformation {
  display: flex;
  width: 100%;
  &.pdfColors {
    .formulaTotalInformation-part {
      .partTitle {
        background: #eee;
      }
    }

    .formulaTotalInformation-section {
      background-color: #fff;
      &.top {
        background-color: #d8dae3;
      }
      &.bottom {
        background-color: #bbd9ea;
      }
      &.total {
        background-color: #abd0b7;
      }
    }
    .toothInfo {
      background: #fff;
    }
  }
  @media screen and (max-width: 1300px) {
    flex-wrap: wrap;
  }
  .formulaTotalInformation-part {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: max-content;
    border: 1px solid var(--formula-total-info-border);

    .halfPart {
      width: 50%;
    }
    .partTitle {
      background: var(--formula-total-info-part-title);
      //border: 1px solid var(--border-color);
      padding: 2px 5px;
    }
  }

  .formulaTotalInformation-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    min-height: 35px;
    width: 100%;
    background-color: var(--main-background-color);
    //border-top: 1px solid var(--formula-total-info-border);
    //border-bottom: 1px solid var(--formula-total-info-border);
    &:last-child {
      // border-bottom: 1px solid #fff
    }
    &.top {
      background-color: var(--formula-total-info-top);
    }
    &.bottom {
      background-color: var(--formula-total-info-bottom);
    }
    &.total {
      background-color: var(--formula-total-info-total);
    }
    img {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      object-fit: contain;
    }
  }
  .toothInfo {
    display: flex;
    //max-width: 240px;
    width: 100%;
    min-height: 30px;
    background: var(--main-background-color);
    border-bottom: 1px solid var(--formula-total-info-border);

    .toothInfo-title {
      display: flex;
      align-items: center;
      background: #3d4973;
      padding: 0 4px;
      color: #fff;
      font-size: 18px;
      min-height: inherit;
      margin-right: 4px;
      //margin-left: 1px;
      &.double {
        min-width: 56px;
      }
    }
    .toothInfo-element {
      display: flex;
      width: 100%;
      flex-direction: column;
      &.totalItem {
        padding-left: 5px;
      }
      .toothInfo-content {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 2px;
        padding-right: 5px;
        &.listItems {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }
        .toothInfo-content-listItem {
          display: flex;
          align-items: center;
        }
        .toothInfo-content-icon {
          display: inline-block;
          width: 33px;
          height: 25px;
          margin-right: 5px;
        }
      }
    }
  }
}
