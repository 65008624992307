$spacing: 8px;

.logoBlock {
  display: flex;
  align-items: center;
  text-decoration: none;
  .logoBlock-img {
    display: block;
    margin-right: $spacing * 2;
  }
  .logoBlock-text {
    .logoBlock-text-title {
      font-size: 24px;
      font-family: "Syncopate", sans-serif;
      color: #fff;
      margin-bottom: $spacing / 2;
    }
    .logoBlock-text-subTitle {
      font-size: 18px;
      font-family: "Syncopate", sans-serif;
      color: #fff;
    }
  }
}
