.formula3dPreview-controls {
  display: block;
  position: absolute;
  top: 50px;
  right: 0;
  font-size: 1.5rem;
  z-index: 10;
  .formula3dPreview-controls-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .formula3dPreview-controls-item-icon {
      display: inline-block;
      width: 40px;
      height: 30px;
      object-fit: contain;
      margin-right: 10px;
    }
    .formula3dPreview-controls-item-title {
      font-size: 14px;
      font-family: "Roboto Condensed";
    }
    .formula3dPreview-controls-item-switch {
      margin-left: auto;
    }
  }
  .formula3dPreview-controls-popUpInfo {
    font-size: 12px;
    line-height: 1.2;
    background: var(--transparent-banner);
    padding: 10px;
    border: 0 solid transparent;
    border-radius: 8px 0 8px 8px;
    max-width: 220px;
    width: 100%;
    margin-right: 10px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  }

  &.fullScreen {
    .formula3dPreview-controls-popUpInfo {
      display: none;
    }
  }

  //i {
  //  color: #3d4973;
  //  cursor: pointer;
  //  transition: all 0.3s;
  //  &:hover {
  //    color: #5f7ff1;
  //  }
  //}
}

//#formulaContainer-controls {
//  display: flex;
//  flex-direction: column;
//  position: absolute;
//  top: 50px;
//  right: 5px;

//}
