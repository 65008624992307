.formulaGenerator {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;

  .sectionSelectTitle {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 325px;
    font-size: 18px;
    color: var(--dark-text);
    display: flex;
    align-items: center;
    background: var(--transparent-banner);
    padding: 8px 10px;
    text-align: justify;
    border: 0 solid transparent;
    border-radius: 8px;
    font-family: "Roboto Condensed";
    font-weight: bold;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    img {
      display: block;
      margin-right: 10px;
      max-width: 40px;
      width: 100%;
      height: auto;
    }
  }

  .formula2dPreviewSection {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .formula3dPreviewSection {
    width: 100%;
    height: 100%;
    min-height: 500px;
    position: relative;
  }
}
//#cardCreaction
//  display: flex
//  flex-direction: column
//  justify-content: space-between
//  width: 100%
//  //height: calc(100vh - 105px)
//  // padding-bottom: 20px
//  overflow-y: scroll
//  //background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgb(208, 207, 221) 50%,rgba(255,255,255,0) 100%)
//  .cardCreation-wrapper
//    display: flex
//    justify-content: space-between
//    height: 100%
//#formula2dPreview
//  min-width: 350px
//  padding: 10px 20px 0
//  .currentAction
//    display: flex
//    align-items: center
//    justify-content: center
//    width: 100%
//    height: 40px
//    // border: 1px solid #3d4973
//    border: 1px solid #ddd
//    .currentAction-item
//      display: flex
//      align-items: center
//      justify-content: center
//      background: #3d4973
//      font-size: 1.4rem
//      color: #fff
//      height: 100%
//      padding: 5px 10px
//      &.double
//        min-width: 80px
//    .currentAction-icon
//      display: block
//      height: calc(100% - 2px)
//      padding: 3px 0
//      background: #fff
//      object-fit: contain
//    .currentAction-name
//      display: flex
//      align-items: center
//      justify-content: center
//      height: 100%
//      width: 100%
//      padding: 5px
//      background: #fff
//
//.formula3dPreviewSection
//  display: flex
//  flex-direction: column
//  width: 100%
//  min-width: 400px
