.formula2DSinuses {
  display: block;
  position: relative;
  //width: 100%;
  margin: 0 auto;
  padding: 10px 10px;
  .formula2DSinuses-info {
    .formula2DSinuses-info-title {
    }
    .formula2DSinuses-info-list {
      display: flex;
      .formula2DSinuses-info-list-item {
        display: flex;
        align-items: center;
        margin-right: 10px;
        span {
          display: block;
          font-weight: bold;
        }
        img {
          display: block;
          width: 35px;
          height: 35px;
          margin-right: 5px;
          object-fit: scale-down;
        }
      }
    }
  }
  .formula2DSinuses-wrapper {
    display: block;
    position: relative;
    width: 100%;
  }
}
