$spacing: 8px;

.card-letsStart {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("./assets/cardLetsStartBg.png");
    background-position: center center;
    background-size: cover;
    border-radius: inherit;
    z-index: -1;
  }
  .card-letsStart-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: $spacing * 3;
  }
  .card-letsStart-subTitle {
    font-size: 18px;
    font-weight: bold;
  }
  .card-letsStart-logo {
    display: block;
    width: 100%;
    height: auto;
    max-width: 200px;
  }
}

.card-helpSlider {
  height: 100%;
  .helpSlider {
    position: relative;
    height: 100%;
    border-radius: inherit;
    overflow: hidden;
    .helpSlider-navigation {
      position: absolute;
      top: $spacing * 2;
      right: $spacing * 2;
      color: #fff;
      z-index: 9;
    }
    .helpSlider-content {
      position: relative;
      width: 100%;
      height: 100%;
      .helpSlider-content-item {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity 0.3s ease-in;
        &.active {
          opacity: 1;
          transition: opacity 0.3s ease-out;
        }
        .helpSlider-content-item-text {
          display: block;
          position: absolute;
          left: $spacing * 2;
          bottom: $spacing * 4;
          right: $spacing * 2;
          font-family: "Roboto", sans-serif;
          font-weight: bold;
          font-size: 28px;
          color: #fff;
          z-index: 2;
        }
        .helpSlider-content-item-img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 1;
        }
      }
    }
  }
}

.mediaLibraryExample {
  .mediaLibraryExample-list-item {
    position: relative;
    cursor: pointer;
    .mediaLibraryExample-list-item-videoIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -70%);
      font-size: 5em;
    }
  }
}

.guide-list {
  .guide-list-item {
    height: 100%;
    .guide-list-item-image {
      display: block;
      width: 200px;
      height: 130px;
      object-fit: scale-down;
      margin: 0 auto;
    }
  }
}
