.videoFullScreen {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  .videoFullScreen-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
  }
  .videoFullScreen-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1550px;
    height: 100%;
    padding: 40px 20px 20px;
    margin: 0 auto;
    .videoFullScreen-title {
      position: absolute;
      top: 5px;
      left: 10px;
      padding: 5px 10px 25px;
      color: #fff;
      z-index: 9;
      text-align: center;
    }
    .videoFullScreen-video {
      max-height: calc(100% - 76px);
    }
    .videoFullScreen-close {
      //position: absolute;
      //top: 5px;
      //right: 5px;

      background: #fff;
      color: #333;
      z-index: 9;
      &:hover {
        background: #bfbfbf;
        color: #fff;
      }
    }
  }
}
