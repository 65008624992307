@import "../mixin";

.stepsCore {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100vh;
  color: var(--dark-text);
  background: var(--main-background) no-repeat center;
  font-family: "Roboto", sans-serif;

  .stepsCore-content {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    .stepsCore-view {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      max-height: calc(100vh - 100px);
    }
  }
}
