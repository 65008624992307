.selectionItemContainer {
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //justify-content: space-between;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: minmax(180px, auto);
  position: relative;
  width: 100%;
  height: 100%;
  //margin: 0 10px 30px;
  //max-width: 300px;
  //@media screen and (max-width: 900px) {
  //  max-width: 240px;
  //}
  .selectionItem {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: minmax(80px, 160px) auto;

    //display: flex;
    //flex-direction: column;
    //align-items: center;
    //justify-content: space-between;
    position: relative;
    width: 100%;
    height: 100%;
    //min-height: 255px;
    padding: 20px 20px 30px 20px;
    border: 0 solid transparent;
    border-radius: 24px;
    background: var(--selection-item-gradient);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: background 0.5s ease-in;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 3px solid transparent;
      border-radius: inherit;
      transition: border-color 0.1s ease-in;
    }
    &.active,
    &:hover {
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      background: var(--selection-item-gradient-hover);
      transition: background 0.5s ease-in;
      &:before {
        border-color: var(--selection-item-border-hover);
      }
    }

    .previewImg {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      margin: 0 auto;
      //max-width: 80%;
    }

    .innerTitle {
      font-size: 1.1em;
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: center;
    }

    .selectBtn {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: linear-gradient(to bottom, var(--primary-red), var(--primary-blue));
      border: 0;
      padding: 11px 24px;
      font-size: 1.1em;
      border-radius: 22px;
      color: #0844a4;
      font-weight: bold;
      line-height: 22px;
      z-index: 1;
      cursor: pointer;
      outline: none;
      &:before {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        background: #fff;
        border: 0 solid transparent;
        border-radius: inherit;
        z-index: -1;
      }
    }
  }
  .outerContent {
    margin-top: 30px;
    .outerTitle {
      display: block;
      width: 100%;
      margin-top: 10px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
    }
    .outerDescription {
      display: block;
      font-family: "Roboto Condensed";
      width: 100%;
      margin-top: 20px;
      font-size: 14px;
      //min-height: 70px;
    }
    //.outerAboutBtn {
    //  display: block;
    //  position: relative;
    //  margin: 20px auto 0;
    //  padding: 8px 10px;
    //  color: #fff;
    //  border: 2px solid #fff;
    //  border-radius: 24px;
    //  font-size: 1.1em;
    //  font-weight: bold;
    //  background: linear-gradient(
    //    to right,
    //    rgba(30, 136, 229, 1) 0%,
    //    rgba(16, 83, 141, 1) 50%,
    //    rgba(30, 136, 229, 1) 100%
    //  );
    //}
  }

  // Disabled styles
  &.disabled {
    opacity: 0.6;
    > .selectionItem {
      background: linear-gradient(to bottom, rgba(204, 204, 204, 0) 0%, rgba(255, 255, 255, 1) 100%);
      &.active,
      :hover {
        background: linear-gradient(to bottom, rgba(204, 204, 204, 0) 0%, rgba(255, 255, 255, 1) 100%);
      }
    }
  }
}
