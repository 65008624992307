.card-profile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  //min-height: 200px;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("./assets/cardProfileBg.png");
    background-position: center center;
    background-size: cover;
    border-radius: inherit;
    z-index: -1;
  }

  .subscriptionBlock {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url("./assets/package_0.png");
      background-position: center center;
      background-size: cover;
      border-radius: inherit;
      z-index: -1;
    }
    &.package-0 {
      &:before {
        background-image: url("./assets/package_0.png");
      }
    }
    &.package-1 {
      &:before {
        background-image: url("./assets/package_1.png");
      }
    }
    &.package-2 {
      &:before {
        background-image: url("./assets/package_2.png");
      }
    }
    &.package-3 {
      &:before {
        background-image: url("./assets/package_3.png");
      }
    }
  }
}
