.rightBarInfo {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: -100%;
  bottom: 0;
  background: var(--main-background-color);
  max-width: 800px;
  width: 100%;
  box-shadow: -7px 0px 7px rgba(0, 0, 0, 0.3);
  transition: right 0.3s ease-in;
  z-index: 99;
  .rightBarInfo-backdrop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
  &.open {
    right: 0;
    .rightBarInfo-backdrop {
      display: block;
    }
  }
  .rightBarInfo-contentView {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1 1;
    height: 100%;
    padding: 20px 20px;
    //overflow-y: scroll;
    z-index: 2;
    .contentContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow-y: hidden;

      .contentMedia {
        .contentMedia-title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .contentMedia-wrapper {
          //position: relative;
          //padding-bottom: 56.25%; /* 16:9 */
          //height: 0;
          margin-bottom: 10px;
        }
      }

      .contentText-title {
        font-size: 18px;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .contentText-content {
        width: 100%;
        height: 100%;
        //padding: 20px 0;
        position: relative;

        .contentText-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          line-height: 1.2;
          overflow-y: scroll;
          height: 100%;

          strong,
          b {
            font-weight: bold;
          }
        }
      }
    }
    .contentActions {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
  .rightBarInfo-contentListWrapper {
    flex: 1 1;
    max-width: 250px;
    background-color: var(--bar-info-content-list-background);
    padding: 10px 10px 0 10px;
    max-height: 100vh;
    z-index: 2;
    .contentMedia-title {
      display: block;
      width: 100%;
      text-align: center;
    }
    .contentMedia-switchers {
      display: flex;
      justify-content: space-around;
      width: 100%;
      padding: 10px;
      text-align: center;
      label {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        width: 100%;
        font-size: 14px;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border: 2px solid transparent;
          border-radius: 50%;
          color: #fff;
          //background: #aaa;
          font-size: 15px;
          margin-bottom: 5px;
          cursor: pointer;
          margin-right: 5px;
          img {
            display: block;
            width: 100%;
            height: 100%;
            //object-fit: cover;
          }
          &:last-child {
            margin-bottom: 0;
          }
          &:hover {
            background: #ddd;
          }
          &.active {
            border-color: var(--primary-blue);
            background: #fff;
            color: var(--primary-blue-text);
          }
        }
      }
    }
    .contentMedia-contentList {
      overflow-y: scroll;
      flex: 1 1;
      height: 100%;

      .contentMedia-contentList-item {
        display: block;
        position: relative;
        cursor: pointer;
        margin-bottom: 20px;
        &.videoItem:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: url("../../assets/images/video_play_icon.png") no-repeat
            center center;
        }
        .image {
          display: block;
          width: 100%;
          height: auto;
        }
        .video {
          position: relative;
          padding-bottom: 56.25%; /* 16:9 */
          height: 0;
          video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        .title {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          padding: 5px 5px;
          background: rgba(0, 0, 0, 0.6);
          text-align: center;
          font-size: 0.9em;
          color: #fff;
        }
      }
    }
  }
}
