.sliderVR {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  .sliderVR-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //background: rgba(0, 0, 0, 0.8);
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(185, 185, 185, 1) 30%,
      rgba(185, 185, 185, 1) 70%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  .sliderVR-container {
    position: relative;
    width: 100%;
    height: 100%;
    //background: #fff;
    .sliderVR-container-title {
      position: absolute;
      top: 5%;
      left: 0;
      right: 0;
      padding: 0 20px;
      @media screen and (max-height: 600px) {
        top: 0;
      }
      .sliderVR-container-title-wrapper {
        font-size: 1.2em;
        text-align: center;
        padding: 20px 20px;
      }
    }
    .sliderVR-container-img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
    .sliderVR-container-controls {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 10%;
      padding: 0 20px;
      z-index: 999;
      @media screen and (max-height: 600px) {
        bottom: 0;
      }
      .sliderVR-container-controls-wrapper {
        margin: 15px auto 0;
        max-width: 600px;
        width: 100%;
      }
    }
  }
  .sliderVR-close {
    position: absolute;
    top: 5px;
    right: 5px;
    background: #fff;
    color: #333;
    z-index: 9;
    &:hover {
      color: #fff;
    }
  }
}
