.orthoBasicTreatment {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 5px 5px 5px 90px;
  .orthoBasicTreatment-syncBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 110px;
    width: 55px;
    height: 55px;
    font-size: 2em;
    background: rgba(255, 255, 255, 0.45);
    border: 4px solid #f1f1f1;
    border-radius: 16px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: all 0.3s;
    z-index: 9;
    &:hover {
      box-shadow: 0 0 6px 4px rgba(0, 0, 0, 0.3);
    }
    &.active {
      border-color: #67b5f5;
      color: #67b5f5;
      box-shadow: none;
      transition: all 0.3s;
    }
  }
  .orthoBasicTreatment-controls {
    display: grid;
    grid-template-columns: auto minmax(auto, 810px) auto;
    grid-gap: 40px;
    align-items: center;
    position: absolute;
    z-index: 9;
    left: 20px;
    right: 20px;
    &.top {
      top: 0;
      align-items: start;
    }
    &.bottom {
      bottom: 20px;
      align-items: end;
    }
    .orthoBasicTreatment-controls-sideIcon {
      display: block;
      width: 100%;
      max-width: 120px;
      height: 100%;
      max-height: 60px;
      object-fit: contain;
      margin: 10px 0 10px auto;
    }
    .orthoBasicTreatment-controls-moreBtn {
      margin: 12px auto 12px 0;
      width: auto;
      padding: 0 12px;
      font-size: 14px;
    }
    .orthoBasicTreatment-controls-about {
      position: absolute;
      top: calc(100% + 5px);
      left: 0;
      right: 0;
      text-align: center;
      //font-weight: bold;
    }
    &.bottom {
      .orthoBasicTreatment-controls-about {
        top: auto;
        bottom: calc(100% + 5px);
      }
    }

    .orthoBasicTreatment-controls-optionList {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      .orthoBasicTreatment-controls-optionList-item {
        display: block;
        width: 85px;
        height: 60px;
        margin: 6px 8px;
        background: rgba(255, 255, 255, 0.45);
        border: 4px solid #f1f1f1;
        border-radius: 16px;
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
        cursor: pointer;
        transition: all 0.3s;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: scale-down;
        }
        &:hover {
          box-shadow: 0 0 6px 4px rgba(0, 0, 0, 0.3);
        }
        &.active {
          border-color: #67b5f5;
          box-shadow: none;
          transition: all 0.3s;
        }
      }
    }
    &.bottom {
      .orthoBasicTreatment-controls-optionList {
        flex-wrap: wrap-reverse;
      }
    }
  }
  .orthoBasicTreatment-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .formula3dPreview {
      .fullScreenBtn {
        top: 100px;
      }
      .formula3dPreview-controls {
        top: 140px;
      }
    }
  }
}
