.selection-type-list-wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  .selection-type-list-disable {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    background: rgba(255, 255, 255, 0.5);
    border: 1px solid transparent;
    border-radius: 24px;
    opacity: 0;
    transition: z-index 0s linear 0.5s, opacity 0.5s linear;
    &.active {
      visibility: visible;
      opacity: 1;
      z-index: 99;
      transition-delay: 0s;
    }
  }
  .selection-type-list {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 30px;
    width: 100%;
    &.list-columns-1 {
      grid-template-columns: 1fr;
    }
    &.list-columns-2 {
      grid-template-columns: 1fr 1fr;
    }
    .selection-type-list-item {
      display: block;
      position: relative;
      max-width: 280px;
      text-align: center;
      border: 0 solid transparent;
      padding: 10px;
      border-radius: 24px;
      box-shadow: 0 0 10px rgba(22, 22, 22, 0.1);
      background: var(--selection-item-gradient);
      transition: box-shadow 0.3s;
      cursor: pointer;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 3px solid transparent;
        border-radius: inherit;
        transition: border-color 0.2s ease-in;
      }
      &:hover {
        box-shadow: 0 0 10px rgba(22, 22, 22, 0.3);
        transition: box-shadow 0.6s;
      }
      &:active {
        box-shadow: inset 0 0 3px rgba(22, 22, 22, 0.3);
      }
      &.active {
        background: var(--selection-item-gradient-hover);
        box-shadow: 0 0 10px rgba(22, 22, 22, 0.2);
      }
      &:hover,
      &:active,
      &.active {
        &:before {
          border-color: var(--selection-item-border-hover);
        }
      }
      &.disabled {
        cursor: auto;
        opacity: 0.4;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }
}
