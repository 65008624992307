@import "./assets/css/reset.scss";
@import "./assets/fonts/roboto/roboto";
@import "./assets/fonts/RobotoCondensed/stylesheet";
@import "./assets/fonts/Syncopate/syncopate_bold/stylesheet";
@import "./assets/fonts/Syncopate/syncopate_regular/stylesheet";

:root {
  --main-background: url("./assets/images/main-bg-light.jpg");
  --main-background-color: #fff;
  --dark-text: #333333;
  --primary-blue: #64b5f6;
  --primary-dark-blue: #10538d;
  --primary-blue-text: #1e88e5;
  --primary-red: #ffb6b6;
  --primary-dark-red: #ae4949;
  --primary-red-text: #fa7575;
  --table-row1: #fdf2f2;
  --table-row2: #f1faff;
  --formula-total-info-part-title: #eee;
  --formula-total-info-top: #d8dae3;
  --formula-total-info-bottom: #bbd9ea;
  --formula-total-info-total: #abd0b7;
  --formula-total-info-border: #e0e0e0;
  --cards-nav-content-btn-text: #333333;
  --cards-nav-content-btn-background: #fff;
  --cards-nav-content-btn-background-hover: #eee;
  --bar-info-content-list-background: #ebebeb;
  --selection-item-gradient: linear-gradient(to bottom, #cccccc00 0%, #fff 100%);
  --selection-item-gradient-hover: linear-gradient(to bottom, #cccccc 0%, #fff 100%);
  --selection-item-border-hover: #ffffffa6;
  --transparent-banner: #ffffffb3;
  --pdf-background: linear-gradient(270deg, white 0%, #cfcfcf 50%, white 100%);
  --fullscreen-background: linear-gradient(180deg, hsla(0, 0%, 100%, 0.7) 0, #d0cfdd 50%, hsla(0, 0%, 100%, 0.7));
  --media-btn-red: #e06c6c;
  --media-btn-blue: #65a1eb;
  --primary-btn-background: linear-gradient(
    to right,
    var(--primary-blue) 0%,
    var(--primary-dark-blue) 50%,
    var(--primary-blue) 100%
  );
  --primary-btn-border: linear-gradient(270deg, white 0%, #cfcfcf 50%, white 100%);
}
html[data-theme="dark"] {
  --main-background: url("./assets/images/main-bg-dark.jpg");
  --main-background-color: #242424;
  --dark-text: #e4e4e4;
  --primary-blue: #64b5f6;
  --primary-blue-text: #9ed2ff;
  --table-row1: #405769;
  --table-row2: #1c3447;
  --formula-total-info-part-title: #5f5f5f;
  --formula-total-info-top: #1d3446;
  --formula-total-info-bottom: #405769;
  --formula-total-info-total: #abd0b7;
  --formula-total-info-border: #405769;
  --cards-nav-content-btn-text: #fff;
  --cards-nav-content-btn-background: #3d4973;
  --cards-nav-content-btn-background-hover: #192f41;
  --bar-info-content-list-background: #585858;
  --selection-item-gradient: linear-gradient(to bottom, #cccccc00 0%, #606060 100%);
  --selection-item-gradient-hover: linear-gradient(to bottom, #606060 0%, var(--main-background-color) 100%);
  --selection-item-border-hover: #555555;
  --transparent-banner: #606060b3;
  --pdf-background: linear-gradient(270deg, black 0%, #2e2e2e 50%, black 100%);
  --fullscreen-background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0, #2e2e2e 50%, rgba(0, 0, 0, 0.7));
  --media-btn-red: #e06c6c;
  --media-btn-blue: #65a1eb;
  --primary-btn-background: linear-gradient(
    to right,
    var(--primary-blue) 0%,
    var(--primary-dark-blue) 50%,
    var(--primary-blue) 100%
  );
  --primary-btn-border: linear-gradient(270deg, #767676 0%, #393939 50%, #767676 100%);
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--main-background-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.stepControls-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 130px;
  min-height: 50px;
  width: 100%;
  color: var(--primary-blue-text);
  background: transparent;
  border: 0 solid transparent;
  text-transform: uppercase;
  border-radius: 36px;
  font-size: 18px;
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s;
  &:before {
    content: "";
    position: absolute;
    top: 4px;
    left: 7px;
    right: 7px;
    bottom: 4px;
    background: var(--main-background-color);
    border: inherit;
    border-radius: inherit;
    z-index: -1;
    transition: all 0.3s;
  }
  &.back {
    margin-right: 10px;
    background: linear-gradient(to right, var(--primary-blue), var(--primary-red));
    > span {
      color: #42a5f5;
      margin-left: 5px;
    }
    > svg {
      color: #e57373;
      transform: rotate(180deg);
    }
  }
  &.next {
    margin-left: 10px;
    background: linear-gradient(to right, #fab6b9, #db5a5a);
    max-width: 140px;
    min-height: 56px;
    border-radius: 29px;
    > span {
      color: #db5a5a;
      margin-right: 5px;
    }
    > svg {
      color: #db5a5a;
    }
  }
  &.save {
    max-width: 190px;
  }
  @media screen and (max-width: 900px) {
    max-width: 60px !important;
    > span {
      display: none;
    }
  }
  &.hidden {
    opacity: 0;
    cursor: default;
  }
}

.btnPrimary {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 170px;
  min-height: 50px;
  width: 100%;
  font-size: 16px;
  background: var(--primary-btn-border);
  margin-top: 10px;
  margin-bottom: 10px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 25px;
  border-color: transparent;
  z-index: 1;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    background: var(--primary-btn-background);
    border: 0 solid transparent;
    border-radius: inherit;
    z-index: -1;
    transition: background 0.3s ease-in;
  }
  &:disabled {
    cursor: default;
    &:before {
      background: linear-gradient(to right, #a8bacb 0%, #87aac9 50%, #a8bacb 100%);
      transition: background 0.3s ease-in;
    }
  }
  &.closeSaveDialogBtn {
    //margin-right: auto;
    //margin-left: 15px;
    //margin-top: 0;
    &:before {
      background: linear-gradient(
        to right,
        var(--primary-red) 0%,
        var(--primary-dark-red) 50%,
        var(--primary-red) 100%
      );
    }
  }
  &.submitSaveDialogBtn {
    //margin-left: auto;
    //margin-right: 15px;
    //margin-top: 0;
  }
  &.submitLicenseDialogBtn {
    margin-left: auto;
    margin-right: auto;
  }

  &.footerAboutBtn {
    transform: translate(0%, -50%);
    position: absolute;
    right: 0;
    top: 50%;
    margin: 0;
  }
  &.footerAlternateBtn {
    margin: 0 10px;
  }

  &.closeRightBarInfoBtn {
    margin: 5px 0;
  }
  &.closeBottomBarInfoBtn {
    margin: 0;
  }
  &.selectBottomBarInfoBtn {
    margin: 0;
    max-width: 280px;
    font-size: 12px;

    &:before {
      background: linear-gradient(
        to right,
        var(--primary-red) 0%,
        var(--primary-dark-red) 50%,
        var(--primary-red) 100%
      );
    }
  }
  // Selection Item
  &.selectionBtnSelect {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
  &.selectionBtnAbout {
    margin: 20px auto 0;
  }
}

.switchSceneToggle {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  max-width: 960px;
  width: 100%;
  z-index: 9;
  @media screen and (max-width: 500px) {
    bottom: 83px;
    left: 10px;
    right: 10px;
  }
  .switchSceneToggle-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .switchSceneToggle-container {
    background: var(--transparent-banner);
  }
}
