.educationStep1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  width: 100%;
  height: 100%;
  .educationStep1-tabs {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 800px;
    justify-content: space-between;
    margin: 0 10px 10px;
    .educationStep1-tabs-icon {
      display: block;
      width: 60px;
      height: 60px;
      object-fit: contain;
      margin-right: 10px;
    }
    .educationStep1-switcher {
      display: flex;
      position: relative;
      width: 100%;
      padding: 5px;
      border: 1px solid var(--primary-blue);
      border-radius: 36px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
      .educationStep1-switcher-arrow {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px;
        transition: transform 0.3s ease-in;
        &.left {
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 60px;
        background: #fff;
        width: 100%;
        padding: 10px 20px;
        font-size: 16px;
        border: 1px solid var(--primary-blue);
        cursor: pointer;
        &:first-child {
          border-top-left-radius: 30px;
          border-bottom-left-radius: 30px;
        }
        &:last-child {
          border-top-right-radius: 30px;
          border-bottom-right-radius: 30px;
        }
        &.active {
          background: var(--primary-blue);
          color: #fff;
        }
      }
    }
  }

  .educationStep1-container {
    display: block;
    position: relative;
    width: 100%;
    max-width: 820px;
    height: 100%;
    .educationStep1-container-item {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .educationStep1-container-item-img {
        display: flex;
        > div {
          padding: 0 10px;
          &:last-child {
            margin-right: 0;
          }
          img {
            display: block;
            width: 100%;
            height: auto;
          }
        }
      }
      .educationStep1-container-item-text {
        display: block;
        background: var(--transparent-banner);
        padding: 10px 10px;
        text-align: justify;
        border: 0 solid transparent;
        border-radius: 8px;
        font-family: "Roboto Condensed";
        margin: 10px 10px;
        min-height: 60px;
        font-size: 15px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
        line-height: 1.2;
        b {
          font-weight: bold;
        }
      }
    }
  }
}
