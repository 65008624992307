$spacing: 8px;

.accountTemplate {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  padding: $spacing * 2 $spacing * 2;
  .accountTemplate-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 300px;
    background-image: url("./assets/heading_1.png");
    background-position: top center;
    background-size: cover;
    z-index: -1;
    &.section_1 {
      background-image: url("./assets/heading_1.png");
    }
    &.section_2 {
      background-image: url("./assets/heading_2.png");
    }
    &.section_3 {
      background-image: url("./assets/heading_3.png");
    }
  }

  .accountTemplate-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
  }
  .accountTemplate-navigation {
    background-color: #ebebeb;
  }
  .accountTemplate-section {
    width: 100%;
  }
}
